
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import { Dictionary } from "vue-router/types/router";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { parseArrayParams } from "@/services/helpers";
import DateRangePicker from "@/components/form-components/DateRangePicker.vue";
import rules from "@/services/helpers/validation-rules";
import DatePicker from "@/components/form-components/DatePicker.vue";

export default Vue.extend({
  name: "ExchangeRate",

  components: { DatePicker, DateRangePicker },

  mixins: [tablePagination, tableFilters],

  data: () => ({
    rules,
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    loading: false as boolean,
    showMenu: false as boolean,
    showAddCurrency: false as boolean,
    loadingExchangeRate: false as boolean,
    model: {} as any,
    x: 0 as number,
    y: 0 as number,
    activeItem: null,
    errorMessages: {} as any
  }),

  computed: {
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 50
        }
      };
      const response = await API.exchangeRates().getData(params);
      const currencies = await API.currency().getList();

      next(vm => {
        vm.model.currencies = currencies.items;
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          await this.$API.exchangeRates().edit(this.model);
          await this.loadData();
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
          this.toggleDialog();
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.exchangeRates().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });
        this.setServerResponse(response);
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loading = false;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (newFilters.hasOwnProperty("date")) {
        newFilters.date = newFilters.date.split(",");
      }

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    setServerResponse({ items, headers }: any) {
      this.headers = [
        ...headers,
        {
          text: "",
          value: "actions",
          width: "100px",
          align: "center",
          sortable: false
        }
      ];
      this.items = items.data;
      this.totalItems = items.total;
    },
    showContextMenu(e: any, item: any) {
      this.activeItem = item.item;
      e.preventDefault();
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        this.showMenu = true;
      });
    },
    toggleDialog(): void {
      this.showAddCurrency = !this.showAddCurrency;
    },
    loadExchangeRate: async function(): Promise<void> {
      this.loadingExchangeRate = true;
      try {
        if (this.model.date) {
          const { currencies } = await API.exchangeRates().getRates(
            this.model.date
          );
          this.model.currencies = currencies;
        } else {
          this.model.currencies.map(
            (item: any) => (item.exchange_rate = null as any)
          );
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
      this.loadingExchangeRate = false;
    }
  }
});
